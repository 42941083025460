<!-- 邀请承接页面 -->
<template>
  <div class="container">
    <div class="box">
      <div
        style="display: flex;flex-direction: column;justify-content: space-between;align-items: center;"
      >
        <span class="hi">HI，{{nickName}}邀请你加入</span>
        <span class="com">{{comName}}</span>
        <el-button @click="() => { $router.push({path:'/pers-signUp',query:{comId: this.comId,comName: this.comName}}) }" type="primary" class="join">加入公司</el-button>
      </div>
    </div>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用

export default {
  name: "persContinue",
  components: {},
  props: {},
  data() {
    return {
        nickName: this.$route.query.user,
        comName: this.$route.query.comName,
        comId: this.$route.query.comId
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {}
};
</script>

<style lang="less" scoped>
.container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .box {
    box-sizing: border-box;
    text-align: center;
    padding: 25px;
    width: 500px;
    height: 320px;
    border-radius: 8px;
    box-shadow: 0px 0px 16px 0px rgba(24, 144, 255, .3);
    background: url("~@/assets/images/continue.png") no-repeat;
    .hi {
      font-size: 16px;
      color: #fff;
    }
    .com {
      font-size: 32px;
      color: #fff;
      margin-bottom: 130px;
    }
    /deep/.join {
      width: 320px;
      height: 48px;
      span{
          font-size: 16px;
      }
    }
  }
}
</style>
